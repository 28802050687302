import * as React from 'react';
import styled from 'styled-components'
import moment from 'moment';
import cn from 'classnames';
import ReactHtmlParser from 'react-html-parser';
import { Close } from '@styled-icons/material-twotone';
import { ContentContainer } from '../Components/Common/ContentContainer';
import { RadioButtons, Checkboxes } from '../Components/Common/Search/SearchFilters';
import { HeroBanner } from '../Components/Common/HeroBanner';
import algoliaSearch, { ISearchHit } from '../helpers/algoliaSearch';
import { Hit } from '@algolia/client-search'
import { Loading } from '../Components/Common/Loading';

interface ISearchObjProps {
    postType?: string
    counter: number
    checked: boolean
}

interface ISearchPageProps {
    location?: {
        state: {
            searchQuery: string;
        }
    }
    uri?: string
}

const SearchPage: React.FC<ISearchPageProps> = ({ location }) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [isSortOpen, setSortOpen] = React.useState(false);
    const [areFiltersOpen, setFiltersOpen] = React.useState(false);
    const [searchHits, setSearchHits] = React.useState<Hit<ISearchHit>[]>([])
    const [postTypes, setPostTypes] = React.useState<ISearchObjProps[]>([])

    const setInitialPostSearch = (state) => {
        algoliaSearch.search<ISearchHit>(state.searchQuery)
            .then((response) => {
                const { hits } = response;
                const dateOrderedHits = hits.sort((hitA, hitB) => {
                    if (!hitA.date) return -1;
                    else if (!hitB.date) return 1
                    else return new Date(hitA.date).getTime() - new Date(hitB.date).getTime();
                });

                const types = dateOrderedHits.map((hit) => (
                    {
                        postType: hit?.postType,
                        checked: false,
                        counter: 1
                    }
                ))

                const uniquePostTypes = new Array <ISearchObjProps>()
                types.map(type => {
                    const foundUniqueType = uniquePostTypes.filter(a => a?.postType === type?.postType);

                    if (foundUniqueType.length > 0) {
                        let found = uniquePostTypes[uniquePostTypes.findIndex(a => a?.postType === type?.postType)];
                        return found = {
                            ...found,
                            counter: found.counter += 1,
                        }
                    }
                    uniquePostTypes.push(type)
                })

                setPostTypes(uniquePostTypes)
                setSearchHits(dateOrderedHits)
                setIsLoading(false)
            })
    }

    const handleCheckboxClick = (event) => {
        if(!event.target.checked) return setInitialPostSearch(location?.state)
        const filterPosts = (post) => {
            if ( post?.postType === event.target.value ) return post
        }

        const results = searchHits.map(hit => filterPosts(hit)).filter((el) => typeof el !== 'undefined')
        if (!results.length) return setInitialPostSearch(location?.state)

        setSearchHits(results);
        const types = searchHits.map((hit) => (
            {
                postType: hit?.postType,
                counter: 1,
                checked: ( hit?.postType === event.target.value) || false,
            }
        ))

        const uniquePostTypes = new Array <ISearchObjProps>()
        types.map(type => {
            const foundUniqueType = uniquePostTypes.filter(a => a?.postType === type?.postType);

            if (foundUniqueType.length > 0) {
                let found = uniquePostTypes[uniquePostTypes.findIndex(a => a?.postType === type?.postType)];
                return found = {
                    ...found,
                    counter: found.counter += 1,
                }
            }
            uniquePostTypes.push(type)
        })

        setPostTypes(uniquePostTypes)
    }

    React.useMemo(() => {
        if (!location?.state?.searchQuery) return;
        setInitialPostSearch(location?.state);
    }, [location?.state?.searchQuery]);

    if (isLoading) return <Loading/>;

    return (
        <>
        <HeroBanner pageSlug="search" minHeight={223} />
        { !isLoading && !!searchHits.length ? (
                <StyledSearchWrapper>
                    <ContentContainer>
                        {(<p>{searchHits.length} results for <span>{`'${location?.state?.searchQuery}'`}</span></p>)}

                        <StyledMobileFilterControlsWrapper>
                            <button
                                onClick={() => setFiltersOpen(!areFiltersOpen)}
                                className={cn('filter-control', { 'is-open': areFiltersOpen })}
                            >
                                Filters (2)
                            </button>
                            <button
                                onClick={() => setSortOpen(!isSortOpen)}
                                className={cn('sort-control', { 'is-open': isSortOpen })}
                            >
                                Sort By: Relavence
                            </button>
                        </StyledMobileFilterControlsWrapper>

                        <div className="inner-wrapper with-sidebar">
                            <aside>
                                <StyledFiltersWrapper>
                                    <section className={cn('checkboxes-section', { 'is-open': areFiltersOpen })}>
                                        {areFiltersOpen  &&
                                                <Close  className={cn('close', { 'is-open': areFiltersOpen })} onClick={() => setFiltersOpen(!areFiltersOpen)} />
                                        }

                                        <h3>Filters</h3>
                                        <Checkboxes buttons={ postTypes } onClick={ handleCheckboxClick }/>
                                    </section>

                                    <section className={cn('radio-buttons-section', { 'is-open': isSortOpen })}>
                                        {isSortOpen &&
                                            <Close className={cn('close', { 'is-open': isSortOpen })} onClick={() => setSortOpen(!isSortOpen)} />
                                        }
                                        <h3>Sort By</h3>
                                        <RadioButtons type={ 'sort' } buttons={ [ { title: 'Latest', value: 'latest' } ] } />
                                    </section>
                                </StyledFiltersWrapper>
                            </aside>

                            <main>
                                <StyledSearchResults>
                                    <ul>
                                        {searchHits.map((post, index) => {
                                                const uri = post.objectID;

                                                return (
                                                    <li key={`search-result--${index}`}>
                                                        <a href={uri} title={post.title}>
                                                            <h4>{post.postType}</h4>
                                                            <h3>{post.title}</h3>
                                                            <div className="summary">
                                                                {ReactHtmlParser(post.excerpt)}
                                                            </div>
                                                            <p className="date">{moment(post.date).format("Do MMM YYYY")}</p>
                                                        </a>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </StyledSearchResults>
                                {// TODO: Implement pagination - need to split all results that are going to be shown (taking filters / sorting into account)
                                // into blocks of maybe 10 and then track which page we are on to then show that specific block of results
                                // E.g. Page 2 shows search results 11 - 20 by accessing something like "paginatedFilteredSearchHits[1]"
                                /* 
                                <Pagination
                                    totalPages={} 
                                    hasNextPage={true}
                                    hasPrevPage={false}
                                />
                                */}
                            </main>
                        </div>   
                    </ContentContainer>
                </StyledSearchWrapper>
            ) :
                <StyledSearchWrapper>
                    <ContentContainer>
                        <h2 className="centered">Sorry, nothing was found.</h2>
                    </ContentContainer>
                </StyledSearchWrapper>
            }
        </>
    )
}

export default SearchPage;

const StyledSearchWrapper = styled.div`
    .content-container {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    main {
        position: relative;
        @media (min-width: ${props => props.theme.screensizes.medium}) {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
    .with-sidebar {
        margin-top: 20px;
    }
    aside {
        margin-bottom: 20px;
        section {
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
            @media (max-width: ${props => props.theme.screensizes.medium}) {
                background: var(--almostWhite);
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                width: 100%;
                height: 100%;
                padding: 20px;
                transform: translateY(-116%);
                opacity: 0;
                transition: all .5s cubic-bezier(0.19, 1, 0.22, 1);
                &.is-open {
                    opacity: 1;
                    transform: translateY(15%);
                }
            }

            svg.close{
                width: 30px;
                height: 30px;
                position: absolute;
                right: 20px;
                top: 30px;
                cursor: pointer;
                @media (min-width: ${props => props.theme.screensizes.medium}) {
                    display: none;
                    opacity: 0;
                }
            }
            label {
                align-items: center;
            }


        }
    }

`;

const StyledFiltersWrapper = styled.div`
    h3 {
        color: var(--nearlyBlue);
        font-size: 16px;
        font-family: ${props => props.theme.fonts.dinNarrow};
        display: block;
        text-transform: uppercase;
        margin-bottom: 15px;
     
    }
    .MuiButtonBase-root {
        color: var(--nearlyNavy) !important;
    }

`;

const StyledSearchResults = styled.div`
    display: flex;
    flex-direction: column;
    h4 {
        color: var(--greatlyGrey);
        text-transform: uppercase;
        font-size: 16px;
        font-weight: bold;
    }
    h3 {
        color: var(--nearlyNavy);
        text-transform: uppercase;
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 20px;
    }
    .summary {
        color: var(--nearlyNavy);
    }
    .date {
        color: var(--greatlyGrey);
        font-size: 16px;
    }
    ul {
        li {
            padding: 25px 0px 0px 0px;
            border-bottom: 1px solid var(--casperGrey);
            &:first-child{
                padding-top: 0px;
            }
        }
    }
`;

const StyledMobileFilterControlsWrapper = styled.div`
    @media (min-width: ${props => props.theme.screensizes.medium}) {
        display: none;
    }

    display: flex;
    button {
       border: 1px solid var(--greatlyGrey);
       padding: 10px 20px;
       color: var(--greatlyGrey);
       text-transform: uppercase;
       font-weight: bold;
       &:first-child {
           margin-right: 15px;
       }
    }

`;