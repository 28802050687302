import * as React from 'react'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';

interface ICheckboxDetails {
	postType?: string
	counter?: number
	checked?: boolean
}
interface ICheckBoxProps {
	buttons: ICheckboxDetails[];
	onClick?: any
}

interface IRadioButtonDetails {
	title?: string;
	value?: string;
}

interface IRadioButtonProps {
	type: string;
	onClick?: any;
	buttons: IRadioButtonDetails[];
}

export const RadioButtons: React.FC<IRadioButtonProps> = (props) => {
	const [value, setValue] = React.useState('latest');

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setValue((event.target as HTMLInputElement).value);
		props.onClick();
	};

	return (
		<FormControl component="fieldset">
			<RadioGroup aria-label={ props?.type } name={ props?.type } value={value} onChange={handleChange}>
				{
					props.buttons.map( (button, index) => (
						<FormControlLabel key={ index } value={ button?.value } control={<Radio />} label={ button?.title } className="styled-radion-button" />
					))
				}
			</RadioGroup>
		</FormControl>
	);
}

export const Checkboxes: React.FC<ICheckBoxProps> = (props) => {
	return (
		<>
			{
				props.buttons.map( (button, index) => (
					<FormControlLabel
						key={ index }
						control={
							<Checkbox
								checked={button?.checked}
								inputProps={{ 'aria-label': 'primary checkbox', title: button.postType, value: button.postType }}
							/>
						}
						onClick={ props.onClick }
						label={ button.postType + ` (${button.counter})`}
					/>
				))
			}
		</>
	);
}