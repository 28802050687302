// Algolia V4 gives strong typescript support but gatsby-plugin-algolia requires V3 so v4 is installed with NPM aliasing
import algoliaClient from 'algoliasearch4';

// This interface is based off of how we build the indices in algolia-config.js
export interface ISearchHit {
    readonly title: string;
    readonly excerpt: string;
    readonly postType: string;
    readonly date?: string;
}

const client = algoliaClient(
    process.env.GATSBY_ALGOLIA_APP_ID!,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY!
);
const index = client.initIndex(process.env.GATSBY_ALGOLIA_INDEX_NAME!);

export default index;
